<template>
  <div class="main-bloc">
    <HeaderSponsor class="d-none d-md-flex" />
    <HeaderSponsorPortable class="d-flex d-md-none" />
    
    <div class="containe">
      <div class="mt-15 prod-first-bloc">
        <h2 class="site-color">Our products and services</h2>
        <p>Because we want the best for you, we are offering assistance for companies who need feedback on their Pitch Deck or advice before fundraising.</p>
      </div>

      <!-- FeedBack Modal -->
      <div>
        <b-modal id="pitch-deck-modal" centered :title="!bookConsultancy ? 'Pitch deck Contact form' : 'Consultancy Contact form'" hide-footer>
          <v-form>
            <FormInlineMessage class="text-danger" v-if="$v.contact.name.$error">
              Please provide a valid name
            </FormInlineMessage>
            <v-text-field
              v-model="contact.name"
              outlined
              placeholder="Your Name"
              dense
              @blur="$v.contact.name.$touch()"
            />

            <FormInlineMessage class="text-danger" v-if="$v.contact.email.$error">
              Please provide a valid email address
            </FormInlineMessage>
            <v-text-field
              v-model="contact.email"
              outlined
              placeholder="example@mail.com"
              dense
              @blur="$v.contact.email.$touch()"
            />

            <FormInlineMessage class="text-danger" v-if="$v.phoneValid.$error">
              Please provide a valid phone number
            </FormInlineMessage>
            <vue-tel-input
              v-model="contact.phone"
              ref="phoneComplete"
              @validate="validatePhone"
              class="mb-4"
              @blur="$v.phoneValid.$touch()"
            />

            <FormInlineMessage class="text-danger" v-if="$v.contact.subject.$error">
              Please provide a valid subject
            </FormInlineMessage>
            <v-text-field
              v-model="contact.subject"
              outlined
              placeholder="Your subject here"
              dense
              @blur="$v.contact.subject.$touch()"
            />

            <FormInlineMessage class="text-danger" v-if="$v.contact.message.$error">
              Please provide a valid message
            </FormInlineMessage>
            <v-textarea
              v-model="contact.message"
              outlined
              placeholder="Your message here"
              rows="3"
              dense
              @blur="$v.contact.message.$touch()"
            />
            <input type="file" class="mb-3" @change="handleFileUpload()" ref="file">
          </v-form>

          <div class="row action-btn d-flex justify-content-between align-items-center">
            <div class="col-12 col-sm-6 recaptcha">
              <FormInlineMessage class="text-danger" v-if="this.captcha_negative_msg">
                Recaptcha is neccessary
              </FormInlineMessage>
                <vue-recaptcha
                sitekey="6Ldv_m8cAAAAAH3w1Hd1vxuxoNiPYMCBjES8rf35"
                @verify="verifyCaptcha"
              ></vue-recaptcha>
            </div>
              
            <div class="col-12" v-if="!loader">
              <v-btn
                sm="2"
                class="white--text"
                style="font-size: 1em; color: #fff;"
                color="#514d7a"
                height="60"
                min-width="70"
                rounded
                block
                :disabled="false"
                @click.prevent.stop="submitContactForm"
              >
                SEND MESSAGE
              </v-btn>
            </div>
            <div v-else class="col-12 text-center">
              <img src="../assets/img/Spinner-1s-200px.gif" alt="" height="60" />
            </div>
          </div>
        </b-modal>
      </div>

      <div class="bg-light mt-3 justify-content-center">
        <div class="container">
          <div class="row col-gap justify-content-center d-grid gap-3">
            <div class="col-12 col-md-4 ms-lg-3 p-0 bg-info text-white">
              <div class="p-3 descriptive-img">
                <h3>Make your Pitch Deck legible, simple and obvious</h3>
              </div>
              <div class="bg-info p-0 m-0">
                <img src="../assets/img/IdeasFundX_pitchdeck.png" alt="Get Feedback for your pitch deck" class="w-100" />
              </div>
            </div>

            <div class="col-12 col-md-6 ms-lg-3 p-10 bg-site text-left">
              <div class="p-2">
                <p><strong class="normal-text">Get Feedback for your Pitch Deck</strong></p>
                <p class="normal-text">Feedback Perks</p>
                <ul>
                  <li class="normal-text">Get Practical and actionable deck feedback</li>
                </ul>
                <p class="normal-text">$300 excl. tax per deck</p>
                <div class="text-center d-block d-sm-none" @click="showIcones = !showIcones">
                  <img src="../assets/img/showMore.png" alt="Show more" width="40" />
                </div>
              </div>
            </div>
          </div>
          <!-- web -->
          <div class="d-none d-sm-block">
            <div class="d-sm-flex align-items-around justify-content-around mt-7">
              <div>
                <img src="../assets/img/attach-pitch-deck.png" alt="“Attach your Pitch Deck" width="43" class="cursor-pointer">
                <p>1 <br> Send your pitch deck</p>
              </div>
              <div>
                <img src="../assets/img/receive-invoice.png" alt="Your invoice for Pitch Deck Feedback" width="45" class="cursor-pointer">
                <p>2 <br> Receive the invoice</p>
              </div>
              <div>
                <img src="../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="52" class="cursor-pointer">
                <p>3 <br> Pay</p>
              </div>
              <div>
                <img src="../assets/img/get-feedbacks.png" alt="Get Pitch Deck Feedback" width="50" class="cursor-pointer">
                <p>4 <br> Get our feedbacks <br> (1/2 pages insights report)</p>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-3 cursor-pointer" style="margin: 0 auto;">
              <div
                @click="$router.push('/products/pitch-deck')"
                id="btn-photo"
                class="white--text mb-3 p-4 header-right"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a; min-width: 200px;">
                  SUBMIT YOUR PITCH DECK    
              </div>
            </div>
          </div>

          <!-- mobile -->
          <div class="d-block d-sm-none hide-feedBackBloc" :class="{ 'show-feedBackBloc': showIcones }">
            <div class="d-sm-flex align-items-around justify-content-around mt-7">
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/attach-pitch-deck.png" alt="Attach your Pitch Deck" width="43" class="cursor-pointer">
                  <p>1 <br> Send your pitch deck.</p>
                </div>
                <div class="col-6">
                  <img src="../assets/img/receive-invoice.png" alt="Your invoice for Pitch Deck Feedback" width="45" class="cursor-pointer">
                  <p>2 <br> Receive the invoice.</p>
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="52" class="cursor-pointer">
                  <p>3 <br> Pay</p>
                </div>
                <div class="col-6">
                  <img src="../assets/img/get-feedbacks.png" alt="Get Pitch Deck Feedback" width="50" class="cursor-pointer">
                  <p>4 <br> Get our feedbacks <br> (1/2 pages insights report)</p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center w-100 mt-3 cursor-pointer" style="margin: 0 auto;">
              <div
                @click="$router.push('/products/pitch-deck')"
                id="btn-photo"
                class="white--text mb-3 pl-10 pr-10 pt-3 pb-3"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a;"
              >
                GET FEEDBACK
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Second bloc  -->
      <div class="bg-light mt-10 justify-content-center">
        <div class="container">
          <div class="row col-gap justify-content-center">
            <div class="col-12 col-md-4 ms-lg-3 p-0 bg-info text-white">
              <div class="bg-info p-0 m-0">
                <img src="../assets/img/IdeasFundX_consultancy.png" alt="Consult our experts to have the winning strategy" class="w-100" />
              </div>
              <div class="p-3 descriptive-img">
                <h3>Have a strategy in place to set yourself up for success</h3>
              </div>
            </div>

            <div class="col-12 col-md-6 ms-lg-3 p-6 bg-site text-left">
              <div class="p-2">
                <p><strong class="normal-text">Consult our experts</strong></p>
                <span class="normal-text">Questioning about your business?</span>
                <ul>
                  <li class="normal-text">Scalability or Profitability</li>
                  <li>Go-to-Market Strategy B2B or B2C</li>
                  <li>Entry Barriers and CAPEX requirements</li>
                  <li>Customer Acquisition Cost vs Customer Lifetime Value...</li>
                </ul>
                <p class="normal-text">Know what you need to do now</p>
                <p class="normal-text">30 or 60 minutes 1:1 consultations.</p>
                <p class="normal-text">$150 / 30 min</p>
                <div class="text-center d-block d-sm-none" @click="showBookIcones = !showBookIcones">
                  <img src="../assets/img/showMore.png" alt="Show more" width="40" />
                </div>
              </div>
            </div>
          </div>

          <!-- web -->
          <div class="d-none d-sm-block">
            <div class="d-flex align-items-around justify-content-around mt-7">
              <div>
                <img src="../assets/img/contact-consultancy.png" alt="contact-us-consultancy" width="50" class="cursor-pointer">
                <p>1 <br> Contact Us <br> to schedule the call.</p>
              </div>
              <div>
                <img src="../assets/img/book-consultancy.png" alt="Book to consult us" width="50" class="cursor-pointer">
                <p>2 <br> Book the day and time.</p>
              </div>
              <div>
                <img src="../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="50" class="cursor-pointer">
                <p>3 <br> Receive the invoice <br> and Pay to confirm <br> the booking.</p>
              </div>
              <div>
                <img src="../assets/img/appointment-consultancy.png" alt="Confirm consultancy appointment" width="50" class="cursor-pointer">
                <p>4 <br> Receive the Appointment <br> Confirmation.</p>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-3 cursor-pointer" style="margin: 0 auto;">
              <div
                @click="$router.push('/products/consultancy')"
                id="btn-photo"
                class="white--text mb-3 p-4"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a; min-width: 200px;">
                  BOOK A CONSULTANCY   
              </div>
            </div>
          </div>

          <!-- mobile -->
          <div class="d-block d-sm-none hide-feedBackBloc" :class="{ 'show-feedBackBloc': showBookIcones }">
            <div class="d-sm-flex align-items-around justify-content-around mt-7">
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/contact-consultancy.png" alt="contact-us-consultancy" width="50" class="cursor-pointer">
                  <p>1 <br> Contact Us <br> to schedule the call.</p>
                </div>
                <div class="col-6">
                  <img src="../assets/img/book-consultancy.png" alt="Book to consult us" width="50" class="cursor-pointer">
                  <p>2 <br> Book the day <br> and time.</p>
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/pay-fees.png" alt="Pay the Pitch Deck Fees" width="50" class="cursor-pointer">
                  <p>3 <br> Receive the invoice <br> and Pay to confirm <br> the booking.</p>
                </div>
                <div class="col-6">
                  <img src="../assets/img/appointment-consultancy.png" alt="Confirm consultancy appointment" width="50" class="cursor-pointer">
                  <p>4 <br> Receive the Appointment <br> Confirmation.</p>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-center mt-3 cursor-pointer" style="margin: 0 auto;">
              <div
                @click="$router.push('/products/consultancy')"
                id="btn-photo"
                class="white--text mb-3 pl-10 pr-10 pt-3 pb-3"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a;">
                  BOOK NOW 
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Cofounders third bloc -->
      <div class="bg-light mt-10 justify-content-center">
        <div class="container">
          <div class="row col-gap justify-content-center">
            <div class="col-12 col-md-4 ms-lg-3 p-0 bg-info text-white">
              <div class="bg-info p-0 m-0">
                <img src="../assets/img/IdeasFundX_cofounders.png" alt="Find your cofounder(s) with ideasvoice.com" class="w-100">
              </div>
              <div class="p-3 descriptive-img">
                <h3>Have 2-3 cofounders to grow faster and attract investors</h3>
              </div>
            </div>

            <div class="col-12 col-md-6 ms-lg-3 p-3 bg-site text-left">
              <p><strong class="normal-text">Find cofounders, business partners, exciting projects with IDEASVOICE.COM</strong></p>
              <p class="normal-text">Connect people with projects nearby you and around the world.</p>
              <span class="site-color"><strong class="normal-text">For entrepreneurs</strong></span>
              <ul>
                <li>Gauge interest in your project</li>
                <li>Build your founding team</li>
                <li>Set up alliances</li>
              </ul>
              <span class="site-color"><strong class="normal-text">For future cofounders and business partners</strong></span>
              <ul>
                <li>Dicover projects and startups who need your Profile</li>
                <li>Check that you and the founder are on the same page</li>
              </ul>
              <div class="text-center d-block d-sm-none" @click="showCofounderIcones = !showCofounderIcones">
                <img src="../assets/img/showMore.png" alt="Show more" width="40" />
              </div>
            </div>
          </div>

          <!-- web -->
          <div class="d-none d-sm-block">
            <p class="mt-8"><strong>Match exciting startups with dream co-founders</strong></p>
            <div class="d-flex align-items-around justify-content-around mt-7">
              <div>
                <img src="../assets/img/ideasvoice-project-driven.png" alt="ideasvoice is project driven" width="50" class="cursor-pointer">
                <div>1 <br> <p>Project Driven</p> <p>Connect people with projects.</p></div>
              </div>
              <div>
                <img src="../assets/img/ideasvoice-relevant-contact.png" alt="ideasvoice ensures relevant contacts" width="50" class="cursor-pointer">
                <div>2 <br> <p>Relevant Contact</p> <p>Define clear separate <br> roles to avoid confusion.</p></div>
              </div>
              <div>
                <img src="../assets/img/ideasvoice-ensures-trusted-relationships.png" alt="ideasvoice ensures trusted relationships" width="70" class="cursor-pointer">
                <div>3 <br> <p>Trusted Relationship</p> <p>Ensure quality and interaction <br> for relevant contacts.</p></div>
              </div>
              <div>
                <img src="../assets/img/ideasvoice-exposure.png" alt="ideasvoice gives exposure to projects" width="55" class="cursor-pointer">
                <div>4 <br> <p>Exposure</p> <p>Give project <br> visibility to attract <br> early adopters. <br> Make unlimited contacts <br> around the world.</p></div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-3 cursor-pointer" style="margin: 0 auto;">
              <a
                id="btn-photo"
                class="white--text mb-3 p-4"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a; min-width: 200px;"
                href="https://www.ideasvoice.com/en/entrepreneur"
                target="_blank"
                :disabled="false">
                  FIND YOUR COFOUNDER   
              </a>
            </div>
          </div>
        </div>

        <!-- mobile -->
        <div class="d-block d-sm-none hide-feedBackBloc" :class="{ 'show-CoufounderBloc': showCofounderIcones }">
          <p class="mt-8"><strong class="text-center">Match exciting startups with dream co-founders</strong></p>
            <div class="d-sm-flex align-items-around justify-content-around mt-4 p-2">
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/ideasvoice-project-driven.png" alt="ideasvoice is project driven" width="50" class="cursor-pointer">
                  <div>1 <br> <p>Project Driven</p> <p>Connect people <br> with projects.</p></div>
                </div>
                <div class="col-6">
                  <img src="../assets/img/ideasvoice-relevant-contact.png" alt="ideasvoice ensures relevant contacts" width="50" class="cursor-pointer">
                  <div>2 <br> <p>Relevant Contact</p> <p>Define clear <br> separate roles <br> to avoid confusion.</p></div>
                </div>
              </div>
              <div class="row justify-content-around">
                <div class="col-6">
                  <img src="../assets/img/ideasvoice-ensures-trusted-relationships.png" alt="ideasvoice ensures trusted relationships" width="60" class="cursor-pointer">
                  <div>3 <br> <p>Trusted Relationship</p> <p>Ensure quality and interaction <br> for relevant contacts.</p></div>
                </div>
                <div class="col-6">
                  <img src="../assets/img/ideasvoice-exposure.png" alt="ideasvoice gives exposure to projects" width="50" class="cursor-pointer">
                  <div>4 <br> <p>Exposure</p> <p>Give project <br> visibility to attract <br> early adopters. <br> Make unlimited contacts <br> around the world</p></div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4 cursor-pointer" style="margin: 0 auto;">
              <div>
                <a
                id="btn-photo"
                class="white--text mb-3 pl-10 pr-10 pt-3 pb-3"
                style="font-weight: bold; font-size: 18px; background-color: #514d7a;"
                color="#514d7a"
                href="https://www.ideasvoice.com/en/entrepreneur"
                target="_blank">
                  FIND COFOUNDER  
                </a>
              </div>
            </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/FooterCms.vue";
import HeaderSponsor from "@/components/HeaderSponsor.vue";
import HeaderSponsorPortable from "@/components/HeaderSponsorPortable.vue";
import VueRecaptcha from "vue-recaptcha";
import FormService from "@/store/services/contact.service.js";
import {
  minLength,
  email,
  sameAs,
  required
} from "vuelidate/lib/validators";


export default {
  components: {
    Footer,
    HeaderSponsor,
    HeaderSponsorPortable,
    VueRecaptcha
  },
  metaInfo: {
    title: 'IDEASFUNDX | Our other products and services for entrepreneurs',
    meta: [
      {
        name: "description",
        content:
          "Advices for your Pitch Deck, Fundraising and Funding.",
      },
      {
        name: "keywords",
        content:
          "secure fundraising, improve pitch deck, business plan, go-to-market strategy, funding, find cofounders for your startup"
      },
      {
        property: "og:image",
        content: "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
      },
      {
        property: "og:image:width",
        content: "1200",
      },
      {
        property: "og:image:height",
        content: "630",
      },
      {
        property: "og:locale",
        content: "en_US",
      },
      {
        property: "og:type",
        content: "website",
      },
      {
        property: "og:title",
        content: "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
      },
      {
        property: "og:description",
        content: "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the deal flow screening process for high quality deals.",
      },
      {
        property: "og:url",
        content: "https://www.ideasfundx.com/",
      },
      {
        property: "og:site_name",
        content: "IdeasFundX",
      },
      {
        name: "twitter:card",
        content:
          "summary_large_image",
      },
      {
        name: "twitter:title",
        content:
          "IDEASFUNDX | Match qualified startups with relevant VCs for +$1 million USD",
      },
      {
        name: "twitter:description",
        content:
          "Secure fundraising and connect with relevant investors for $1MM+ funding. Optimize the dealflow screening process for high quality deals.",
      },
      {
        name: "twitter:image",
        content:
          "https://www.ideasfundx.com/themes/website/assets/img/ideasfundx-header.png",
      },
      {
        name: "twitter:image:width",
        content:
          "1200",
      },
      {
        name: "twitter:image:height",
        content:
          "630",
      },
    ]
  },
  data() {
    return {
      showIcones: false,
      showBookIcones: false,
      showCofounderIcones: false,
      loader: false,
      submit_success: false,
      submit_failure: false,
      captcha_negative_msg: false,
      captcha: false,
      phoneValid: false,
      file: null,
      bookConsultancy: false,
      contact: {
        name: null,
        email: null,
        phone: null,
        subject: "IDEASFUNDX - Get Feedback for my pitch deck",
        message: null,
      },
    };
  },

  validations: {
    phoneValid: { sameAs: sameAs(() => true) },
    contact: {
      required,
      name: { required, minLength: minLength(2) },
      email: { required, email },
      subject: { required, minLength: minLength(2) },
      message: { required, minLength: minLength(2) },
    },
  },

  methods: {
    verifyCaptcha(result) {
      this.captcha = result;
      if (this.captcha) {
        this.captcha_negative_msg = false;
      }
    },
    validatePhone(params) {
      this.phoneValid = params.valid;
      this.contact.phone = params.number;
    },

    openBookConsultancy() {
      this.bookConsultancy = true;
      this.contact.subject = "IDEASFUNDX - Book a Consultancy";
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    submitContactForm() {
      this.$v.$touch();
      if (this.$v.$error) {
        return false;
      }
      
      if (this.captcha) {
        let data = new FormData();
        this.loader = true;

        data.append("name", this.contact.name);
        data.append("email", this.contact.email);
        data.append("phone", this.contact.phone);
        data.append("subject", this.contact.subject);
        data.append("message", this.contact.message);
        data.append("attached", this.file);

        if (this.bookConsultancy) {
          data.append("bookConsultancy", true);
          FormService.submitPitchDeckContactForm(data)
          .then((res) => {
            this.contact.name = null;
            this.contact.phone = null;
            this.contact.email = null;
            this.contact.message = null;
            this.file = null;
            this.$v.$reset(); //reset errors
            this.$bvModal.hide('pitch-deck-modal');
            this.$toast.open({              
              message: "Your request to book a consultancy is sent to our team. We will come back to you within 48 hours",
              type: 'success',
              position: 'top-right',
              duration: 5000
            });
            this.loader = false;
          })
          .catch((e) => {
            this.loader = false;
            this.$toast.open({
              message: e.response.data.errors[0].title,
              type: 'warning',
              position: 'top-right',
              duration: 5000
            });
          });
        } else {
          data.append("bookConsultancy", false);
          if (!this.file) {
            this.$toast.open({
              message: "Please upload your pitch deck to get feedback from our team of experts",
              type: "error",
              position: "top-right",
              duration: 5000,
            });
            this.loader = false;
            return false;
          }
          FormService.submitPitchDeckContactForm(data)
          .then((res) => {
            this.contact.name = null;
            this.contact.phone = null;
            this.contact.email = null;
            this.contact.message = null;
            this.file = null;
            this.$v.$reset(); //reset errors
            this.$bvModal.hide('pitch-deck-modal');
            this.$toast.open({
              message: "Your Pitch Deck is sent to our team. We will come back to you within 48 hours",
              type: 'success',
              position: 'top-right',
              duration: 5000
            });
            this.loader = false;
          })
          .catch((e) => {
            this.loader = false;
            this.$toast.open({
              message: e.response.data.errors[0].title,
              type: 'warning',
              position: 'top-right',
              duration: 5000
            });
          });
        }

      } else {
        this.loader = false;
        this.captcha_negative_msg = true;
      }
    },
  },
  mounted() { },
};
</script>

<style scoped>
  @import '../assets/styles/products.css';
</style>
